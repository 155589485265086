<template>
  <div class="error">
    <el-empty description="暂无页面"></el-empty>
    <div>{{time}}秒后回到首页</div>
  </div>
</template>

<script>
export default {
  name: "VirtualOfficialWebsiteError",

  data() {
    return {
      time: 3,
      timer:''
    };
  },

  mounted() {
    this.timer = setInterval(() => {
      this.time--;
      if (this.time == 0) {
        clearInterval(this.timer);
        if (this._isMobile()) {
          this.$router.replace("/m_main");
        } else {
          this.$router.replace("/");

     
        }
      }
    }, 1000);
  },
    beforeDestroy(){
        clearInterval(this.timer)
    },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}
</style>